/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { Typography } from "@mui/material";
import { BeginYourJourney } from "../homepage/BeginYourJourney";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { OurServiceCard } from "./OurServiceCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState } from "react";

const CustomLeftArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{ position: "absolute", left: 10, zIndex: 10 }}
  >
    ◀
  </button>
);
const CustomRightArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{ position: "absolute", right: 10, zIndex: 10 }}
  >
    ▶
  </button>
);

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const slides = [
  { title: "High Vol Emails", content: "Description here" },
  { title: "PPC", content: "PPC content" },
  { title: "Financial Analysis", content: "Maximize your online visibility." },
  { title: "Paid Ads", content: "Grow your brand awareness." },
  { title: "Custom Work", content: "Other related content" },
];

export const OurServices = () => {
  const [activeIndex, setActiveIndex] = useState(1); // Start on center card

  const handleBeforeChange = (previousSlide, { currentSlide }) => {
    setActiveIndex(currentSlide);
  };

  const handleAfterChange = (previousSlide, state) => {
    setActiveIndex(state.currentSlide - 1); // Update index dynamically
  };
  return (
    <>
      <Navbar />
      <div
        className="flex flex-col lg:flex md:pt-24 sm:pt-10 xs:pt-8"
        style={{
          backgroundImage: `url(/image.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <div className="text-center text-white p-24 flex flex-col justify-center items-center">
          <Typography
            sx={{
              typography: {
                md: "h3",
                sm: "h4",
                xs: "h4",
              },
            }}
            style={{ fontWeight: 700 }}
          >
            Our Services
          </Typography>
          <Typography variant="h6" className="py-4 flex md:w-2/3">
            Discover our expertise in developing a wide range of powerful SaaS
            applications tailored to meet your business need
          </Typography>
        </div>
        <div className="text-center text-white pb-2">Get in Touch</div>

        <div
          style={{
            backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Vector3.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
          className="text-white px-16 pt-24 pb-16"
        >
          <div className="lets-talk text-center flex w-full items-center justify-center mb-8">
            Our Expertise
          </div>
          <div className=" ">
            <Carousel responsive={responsive} itemClass="carousel-item-spacing">
              <OurServiceCard
                title={"HIGH VOL EMAILS"}
                description={
                  "Maximize your online visibility and achieve measurable results with expertly managed paid advertising campaigns. Generate leads or boost sales. our tailored strategies consistently ensure success for your business goals and get your results."
                }
                img={"/eemail.png"}
              />
              <OurServiceCard
                title={"PAID ADS"}
                description={
                  "Managing large email volumes can feel overwhelming, but our expert services simplify the process. Be it customer inquiries, marketing campaigns, or internal communications, we make your workflows efficient, organized, and stress-free."
                }
                img={"/PostAds.png"}
              />
              <OurServiceCard
                title={"CUSTOM WEB DEV"}
                description={
                  "Maximize your online visibility and achieve measurable results with expertly managed paid advertising campaigns. Whether you are looking to generate leads, boost sales, or grow brand awareness, our tailored strategies deliver success."
                }
                img={"/LaptopCoding.png"}
              />
              <OurServiceCard
                title={"FINANCIAL ANALYSIS & PLANNING"}
                description={
                  "Maximize your online visibility and achieve measurable results with expertly managed paid advertising campaigns. Whether you're looking to generate leads, boost sales, or grow brand awareness, our tailored strategies deliver success."
                }
                img={"/Group848.png"}
              />
              <OurServiceCard
                title={"PPC"}
                description={
                  "Unlock the full potential of your online advertising with our tailored PPC solutions. We create data-driven campaigns designed to maximize ROI, drive high-quality traffic, and convert clicks into loyal customers."
                }
                img={"/Pay.png"}
              />
            </Carousel>
          </div>
        </div>
      </div>
      <BeginYourJourney />
      <Footer />
    </>
  );
};
