import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Toolbar,
  Typography,
  Tab,
  Tabs,
  Grid,
  Box,
  Button,
  IconButton,
  Drawer,
  Menu,
  MenuItem,
  Divider,
  Tooltip
} from "@mui/material";
import { NavItems } from "./navItems";
import { styled } from "@mui/system";
import { logoutUser } from "../../store/userProfileSlice";
import { removebanner } from "../../store/alertSlice.js";
import DashboardIcon from "@mui/icons-material/Dashboard";
const option =
  "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/NavBar/hamBurgLight.png";
const logo =
  "https://gistcdn.githack.com/hamza-smh/77d5858b1df2148ef86fb8f4f65791f9/raw/193bc3b95c5964aefaca2250dad889f9177eb9ed/smallLogoFullName.svg";
const logout =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logout.png";
const aiMagic =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/AiMagic.png"
const spark = "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/sparkling.png"

const Animation = styled("div")(({ width, left }) => ({
  position: "absolute",
  height: "60%",
  top: 13,
  zIndex: 0,
  background: "#FFFFFF44",
  borderRadius: "40px",
  boxShadow: "0px 4px 4px 0px #00000080",
  transition: "all .5s ease 0s",
  width: width,
  left: left,
}));

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [hoverTab, setHoverTab] = useState(null);
  const [transparent, setTransparent] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [disableHovered, setDisableHovered] = useState(false);

  const open = Boolean(anchorEl);

  const user = useSelector((state) => state.userProfile.userProfile);

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(removebanner());
    dispatch(logoutUser());
    navigate("/");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigateDashboard = () => {
    navigate("/dashboard");
    setAnchorEl(null);
  };

  useEffect(() => {
  const currentPath = window.location.pathname
  const matchingTab = NavItems.findIndex(
    item => currentPath === item.link || currentPath.startsWith(`${item.link}/`)
  )
  if (matchingTab !== -1) {
    setActiveTab(matchingTab)
  }
}, [])

  useEffect(() => {
    function handleScroll() {
      const isTransparent = window.scrollY === 0;
      setTransparent(!isTransparent);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setHoverTab(null);
  };

  const handleMouseEnter = (index) => {
    setHoverTab(index);
  };

  const handleMouseLeave = () => {
    setHoverTab(null);
  };

  const getAnimationStyle = () => {
    const tab = hoverTab !== null ? hoverTab : activeTab;
    return {
      width: NavItems[tab]?.width,
      left: NavItems[tab]?.left,
    };
  };

  const nameLength = user?.last_name?.length + user?.first_name?.length;

  return (
    <>
      <Box>
        <AppBar
          position="fixed"
          className="webAppBar"
          sx={{
            backgroundColor: transparent ? "#2D2D2D" : "transparent",
            paddingX: { md: "90px", sm: "50px", xs: "0px" },
            paddingY: "15px",
          }}
        >
          <Toolbar
            className="spaceBtwn"
            sx={{
              paddingRight: { md: "0px", sm: "30px", xs: "15px" },
              paddingLeft: { md: "0px", sm: "30px", xs: "15px" },
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              className="menuButton"
              sx={{
                display: {
                  md: "none",
                  sm: "block",
                },
              }}
            >
              <img src={option} alt="" />
            </IconButton>

            <Grid
              sx={{
                display: {
                  md: "block",
                  sm: "block",
                  xs: "none",
                },
              }}
            >
              <Button disableRipple onClick={() => (navigate("/"))}>
                <img src={logo} alt="" />
              </Button>
            </Grid>

            <Toolbar
              className="webToolBar"
              sx={{
                display: { md: "flex", sm: "none", xs: "none" },
                width: { md: "fit-content", sm: "50%", xs: "40%" },
                overflowX: { md: "hidden", sm: "auto" },
              }}
            >
              <Tabs
                value={activeTab}
                onChange={handleChange}
                TabIndicatorProps={{ style: { display: "none" } }}
                variant="fullWidth"
              >
                {NavItems.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab.label}
                    style={{ minWidth: { md: tab.width, sm: tab.width * 0.5 } }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                    onClick={() => (navigate(tab.link))}
                    disableRipple
                    className="navItem"
                    sx={{
                      fontSize: { md: "16px", sm: "14px" },
                      padding: { md: "5px 25px", sm: "5px 10px" },
                    }}
                  />
                ))}
              </Tabs>
              <Animation {...getAnimationStyle()} />

              <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                className="transparent"
                sx={{
                  '& .MuiDrawer-paper': {
                    background: '#000000CC',
                    boxShadow: 'none',
                  },
                }}
              >
                <Grid
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                  className="webPresentation"
                //sx={{ background:"#700408"}}
                >
                  {NavItems.map((item, index) => (
                    <Button
                      disableFocusRipple
                      disableRipple
                      disableElevation
                      className="buttonContainer"
                      key={index}
                      onClick={() => (navigate(item.link))}
                      sx={{
                        //border:"3px solid #9e0308",
                        boxShadow: item.link === window.location.pathname ? "2px 5px 10px #9e0308" : "1px 3px 5px #FFFFFF",
                        background: item.link === window.location.pathname ? "#9e0308" : "#000000CC"

                      }}
                    >

                      <Grid item md={10} sx={{ textDecoration: "none" }}>
                        {item.label}
                      </Grid>
                    </Button>
                  ))}
                </Grid>
              </Drawer>
            </Toolbar>

            <Grid sx={{ display: "flex" }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => (navigate("/register"))}
                className="signUpBtn"
                sx={{
                  display:
                    user && Object.keys(user).length > 0 ? "none" : "flex",
                  fontSize: { md: "14px", sm: "12px", xs: "10px" },
                  marginX: "5px",
                }}
              >
                Sign up
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={() => (navigate("/login"))}
                className="loginBtn"
                sx={{
                  display:
                    user && Object.keys(user).length > 0 ? "none" : "flex",
                  fontSize: { md: "14px", sm: "12px", xs: "10px" },
                  marginX: "5px",
                }}
              >
                Login
              </Button>

              {/* <Tooltip
                title="Coming Soon!" 
                arrow
              >
                <Button
                  disabled
                  variant="contained"
                  size="large"
                  onMouseEnter={() => setDisableHovered(true)}
                  onMouseLeave={() => setDisableHovered(false)}
                  onClick={() => navigate("/ai-calculator")}
                  sx={{
                    marginX: user && Object.keys(user).length > 0 ? "15px":"5px",
                    background: "linear-gradient(180deg, #FFA858 0%, #994900 100%)",
                    border: "1px solid #282828",
                    borderRadius: "40px",
                    textTransform: "capitalize",
                    color: "#fff",
                    fontWeight: "700",
                    whiteSpace: "nowrap",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    position: "relative", 

                    "&::before": {
                      content: "''",
                      position: "absolute",
                      inset: 0,
                      borderRadius: "38px",
                      padding: "1px",
                      background: "linear-gradient(180deg, #FFA858 0%, #994900 100%)",
                      WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                      transform: {
                        xs: "scale(1.2)",
                        sm: "scale(1.15)",
                        md: "scale(1.125)",
                      },
                    },

                    "&::after": {
                      content: "''",
                      position: "absolute",
                      inset: 0,
                      borderRadius: "38px",
                      padding: "1px",
                      background: "linear-gradient(180deg, #FFA858 0%, #994900 100%)",
                      WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                      transform: {
                        xs: "scale(1.1)",
                        sm: "scale(1.05)",
                        md: "scale(1.075)",
                      },
                    },
                  }}
                >
                  <img
                    src={spark}
                    alt=""
                    style={{ position: "absolute", top: "-10px", right: "-20px", height: "15px", width: "15px" }}
                  />

                  Ai Magic
                </Button>
              </Tooltip> */}

              <Button
                disableRipple
                className="profile"
                onClick={handleClick}
                sx={{
                  display:
                    user && Object.keys(user).length > 0 ? "flex" : "none",
                  backgroundColor: {
                    md: "#3434344D",
                    sm: "#3434344D",
                    xs: "transparent",
                  },
                  padding: {
                    md: "0px 15px 0px 0px",
                    sm: "0px 15px 0px 0px",
                    xs: "0px",
                  },
                  fontSize: { md: "16px", sm: "16px", xs: "10px" },
                  marginX: "5px",
                  color: "#FFF",
                  "&:hover": {
                    backgroundColor: {
                      md: "#3434344D",
                      sm: "#3434344D",
                      xs: "transparent",
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    display: { md: "flex", sm: "flex", xs: "none" },
                    alignItems: "center",
                  }}
                >
                  {/* <img src={user?.image_url} alt="" className="userDp" /> */}

                  <Avatar
                    alt="Remy Sharp"
                    src={user?.image_url}
                    sx={{ width: 100, height: 100, marginRight: { md: "20px", sm: "20px", xs: "0px" } }}
                    className="userDp"
                  />{" "}


                  {user?.first_name > 8
                    ? `${user?.first_name?.substring(0, 8)}...`
                    : user?.first_name}

                  <span
                    className="nameBold"
                    style={{ display: { md: "flex", sm: "none", xs: "none" } }}
                  >
                    {" "}
                    {nameLength > 8
                      ? `${user?.last_name?.substring(0, 2)}...`
                      : user?.last_name}
                  </span>
                </Typography>
                <Typography
                  sx={{ display: { md: "none", sm: "none", xs: "flex" } }}
                >
                  {/* <img src={user?.image_url} alt="" className="userDp" /> */}

                  <Avatar
                    alt="Remy Sharp"
                    src={user?.image_url}
                    sx={{ width: 100, height: 100, marginRight: { md: "20px", sm: "20px", xs: "0px" } }}
                    className="userDp"

                  />{" "}
                </Typography>
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onMouseLeave={handleClose}
                PaperProps={{
                  sx: {
                    backgroundColor: { md: "#575757DD", sm: "#575757DD", xs: "#575757FF" },
                    color: "white",
                    borderRadius: "30px",
                    padding: "5px 15px 5px 15px",
                    width: "225px",
                  },
                }}
              >
                <MenuItem
                  onClick={handleNavigateDashboard}
                  sx={{
                    borderRadius: "15px",
                    "&:hover": { background: "#0D0D0DAA" },
                  }}
                >
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <DashboardIcon
                      style={{
                        marginRight: "8px",
                        padding: "0px",
                      }}
                    />
                    Go to Dashboard
                  </Typography>
                </MenuItem>
                <Divider sx={{ backgroundColor: "#fff" }} />
                <MenuItem
                  onClick={handleLogout}
                  sx={{
                    borderRadius: "15px",
                    "&:hover": { background: "#0D0D0DAA" },
                  }}
                >
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={logout ? logout : ""}
                      alt="Logout"
                      style={{ marginRight: "10px", marginLeft: "4px" }}
                    />
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};
export default Navbar;
