import { useDispatch, useSelector } from "react-redux";
import { React,useState, useEffect } from "react";
import { Grid, Typography, Toolbar, Tabs, Tab, Button, Link, InputAdornment, FormControl, FormLabel, RadioGroup,  Radio, TextField, FormControlLabel, Checkbox, IconButton,useMediaQuery, Box, FormHelperText, Skeleton, Menu, MenuItem, Select, InputLabel } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { styled } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { error as errorMsg, success } from "../../store/alertSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { addPaymentMethod, addBankDirectPaymentMethod, addSepaDebitPaymentMethod, addCardPaymentMethod } from "../../store/paymentMethodSlice.js";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const masterCard = "https://gistcdn.githack.com/hamza-smh/5d9cb5c8936222b6c436169b73d4cbb0/raw/e2e3018e6bbab78ab707bbe151807c949939d6e9/masterCard.svg"
const stripeBlue = "https://gistcdn.githack.com/hamza-smh/7c894ab172c3f48094db88a14e1bcb21/raw/9d48be7b5ecf1da4fed962b092f55855762bf208/stripeBlue.svg"
const stripe = "https://gistcdn.githack.com/hamza-smh/9221ed5a952f3290dac0578867ece369/raw/2bff5e46f5ad201fed9c169901e61e545d77def6/stripeWhite.svg";
const paypal = "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/paypalFull.png"
const visa = "https://gistcdn.githack.com/hamza-smh/b458542799dfd5c25362ee15c6395556/raw/ef73c34fd8bb8ffc9da8ec68ac66f60771dfa697/visa.svg"
const visaWhite = "https://gistcdn.githack.com/hamza-smh/9c5c05db01cf2df85f1405cd900a14b4/raw/ab9e2a5d912271ec06274c8b20fe2bf75402bab1/visaWhite.svg"

const method = [
    { label: 'Stripe', width: '50%', left: '0%' },
    { label: 'Paypal', width: '50%', left: '50%' },
];
const Animation = styled("div")(({ width, left }) => ({
    position: "absolute",
    height: "100%",
    top: 0,
    zIndex: 0,
    background: "#E50000",
    borderRadius: "30px",
    border: "1px solid #FFF",
    transition: "all .5s ease 0s",
    width: width,
    left: left,
}));

const defaultValues = {
    email: "",
    password: "",
    cardHolderName: "",
    cardNumber: "",
    cardExpiry: "",
    cvc: "",
    addressLine1: "",
    postalCode: "",
    addressLine2: "",
    city: "",
    country: "",
    account_number: "",
    routing_number: "",
    account_holder_name: "",
    account_holder_type: "",
    iban: "",
};

const AddPaymentMethod = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const themeMode = useSelector((state) => state.themeMode.mode);
    const [activeTab, setActiveTab] = useState(0);
    const [hoverTab, setHoverTab] = useState(null);
    const [selectedTab, setSelectedTab] = useState("Stripe");
    const [showPassword, setShowPassword] = useState(false);
    const isCustomRange = useMediaQuery(
        "(min-width:900px) and (max-width:1336px)"
    );
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMethodOption, setSelectedMethodOption] = useState("Stripe (Credit/Debit Card)")

    const createSchema = (tab, methodOption) => yup.object().shape({
        email: tab === 'Paypal' ? yup.string().required('Email is required') : yup.string(),
        password: tab === 'Paypal' ? yup.string().required('Password is required') : yup.string(),
        cardHolderName: tab === 'Stripe' && (methodOption === 'Stripe (Credit/Debit Card)' || methodOption === 'Stripe (Credit/Debit Card) New') ? yup.string().required('Card Holder Name is required') : yup.string(),
        cardNumber: (tab === 'Stripe' && methodOption === 'Stripe (Credit/Debit Card)') ? yup.mixed().required('Card Number is required') : (tab === 'Stripe' && methodOption === 'Stripe (Credit/Debit Card) New') ? yup.string().required('Card Number is required') : yup.mixed(),
        cardExpiry: (tab === 'Stripe' && methodOption === 'Stripe (Credit/Debit Card)') ? yup.mixed().required('Card Expiry is required') : (tab === 'Stripe' && methodOption === 'Stripe (Credit/Debit Card) New') ? yup.string().required('Card Expiry is required') : yup.mixed(),
        cvc: (tab === 'Stripe' && methodOption === 'Stripe (Credit/Debit Card)') ? yup.mixed().required('CVC is required') : (tab === 'Stripe' && methodOption === 'Stripe (Credit/Debit Card) New') ? yup.string().required('CVC is required') : yup.mixed(),
        account_number: tab === 'Stripe' && methodOption ==='Stripe (Bank Direct)' ? yup.string().required('Account Number is required') : yup.string(),
        routing_number: tab === 'Stripe' && methodOption ==='Stripe (Bank Direct)' ? yup.string().required('Routing Number is required') : yup.string(),
        account_holder_name: tab === 'Stripe' && (methodOption ==='Stripe (Bank Direct)' || methodOption ==='Stripe (SEPA Debit)') ? yup.string().required('Account Holder Name is required') : yup.string(),
        account_holder_type: tab === 'Stripe' && methodOption ==='Stripe (Bank Direct)' ? yup.string().required('Account Holder Type is required') : yup.string(),
        iban: tab === 'Stripe' && methodOption ==='Stripe (SEPA Debit)' ? yup.string().required('Iban is required') : yup.string(),
    });
    
    const [schema, setSchema] = useState(createSchema(selectedTab, selectedMethodOption));

    const { handleSubmit, register, reset, control, watch, formState } = useForm({
        defaultValues,
        mode: "all",
        resolver: yupResolver(schema),
    });

    const { errors } = formState;

    useEffect(() => {
        reset();
        setSchema(createSchema(selectedTab, selectedMethodOption));
    }, [selectedTab, selectedMethodOption]);

    useEffect(() => {
        const currentPath = window.location.pathname;
        const matchingTab = method.findIndex((item) => item.link === currentPath);
        if (matchingTab !== -1) {
            setActiveTab(matchingTab);
        }
    }, []);

    const getAnimationStyle = () => {
        const tab = hoverTab !== null ? hoverTab : activeTab;
        return {
            width: method[tab]?.width,
            left: method[tab]?.left,
        };
    };

    const [selectedValue, setSelectedValue] = useState('');

    const handleChangeRadio = (event) => {
        setSelectedValue(event.target.value);
    };


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleChange = (event, newValue) => {
        // setActiveTab(newValue);
        // setHoverTab(null);
        // setSelectedTab(event.target.value);
    };
    const handleMouseEnter = (index) => {
        setHoverTab(index);
    };

    const handleMouseLeave = () => {
        setHoverTab(null);
    };

    async function onSubmit(data) {
        setLoading(true);

        if(selectedTab === "Stripe"){
            if(selectedMethodOption === "Stripe (Credit/Debit Card)"){
                if (!stripe || !elements) {
                    setLoading(false);
                    return;
                }
                try {
                    const cardElement = elements?.getElement(CardNumberElement);
                    const { token, error } = await stripe.createToken(cardElement);
                    if (!token) {
                        dispatch(errorMsg("Invalid card details"));
                        if (error && error?.message) {
                            console.error(error.message);
                            dispatch(errorMsg(error.message));
                        }
                        setLoading(false);
                        return;
                    }
                    const payment_method = {
                        source: token?.id,
                        payment_method_type: "stripe",
                        default_method: data?.default_method,
                        cardHolderName: data?.cardHolderName,
                        addressLine1: data?.addressLine1,
                        postalCode: data?.postalCode,
                        addressLine2: data?.addressLine2,
                        city: data?.city,
                        country: data?.country,   
                    }
                    dispatch(addPaymentMethod(payment_method)).then((resp) => {
                        setLoading(false);
                        if (resp?.payload?.status === 201) {
                            dispatch(success("new payment method added successfully!"));
                            navigate(`/dashboard/payments/manage-payment-methods`);
                        } 
                    });
                } catch (error) {
                    console.error("Error:", error);
                    setLoading(false);
                }
            }
            else if(selectedMethodOption === "Stripe (Bank Direct)"){
                if (!data?.account_number || !data?.routing_number || !data?.account_holder_name || !data?.account_holder_type) {
                    dispatch(errorMsg("Please fill the details"));
                    setLoading(false);
                    return;
                }
                try {
                    const bank_details = {
                        account_number: data?.account_number,
                        routing_number: data?.routing_number,
                        account_holder_name: data?.account_holder_name,
                        account_holder_type: data?.account_holder_type,
                        default_method: data?.default_method,
                        addressLine1: data?.addressLine1,
                        postalCode: data?.postalCode,
                        addressLine2: data?.addressLine2,
                        city: data?.city,
                        country: data?.country,   
                    }
                    dispatch(addBankDirectPaymentMethod(bank_details)).then((resp) => {
                        setLoading(false);
                        if (resp?.payload?.status === 201) {
                            dispatch(success("new payment method added successfully!"));
                            navigate(`/dashboard/payments/manage-payment-methods`);
                        } 
                    });
                } catch (error) {
                    console.error("Error:", error);
                    setLoading(false);
                }
            }
            else if(selectedMethodOption === "Stripe (SEPA Debit)"){
                if (!data?.iban || !data?.account_holder_name) {
                    dispatch(errorMsg("Please fill the details!"));
                    setLoading(false);
                    return;
                }
                try {
                    const sepa_details = {
                        iban: data?.iban,
                        account_holder_name: data?.account_holder_name,
                        default_method: data?.default_method,
                        addressLine1: data?.addressLine1,
                        postalCode: data?.postalCode,
                        addressLine2: data?.addressLine2,
                        city: data?.city,
                        country: data?.country,   
                    }
                    dispatch(addSepaDebitPaymentMethod(sepa_details)).then((resp) => {
                        setLoading(false);
                        if (resp?.payload?.status === 201) {
                            dispatch(success("new payment method added successfully!"));
                            navigate(`/dashboard/payments/manage-payment-methods`);
                        } 
                    });
                } catch (error) {
                    console.error("Error:", error);
                    setLoading(false);
                }
            }
            else if(selectedMethodOption === "Stripe (Credit/Debit Card) New"){
                if (!data?.cardHolderName || !data?.cardNumber || !data?.cardExpiry || !data?.cvc) {
                    dispatch(errorMsg("Please fill the details!"));
                    setLoading(false);
                    return;
                }
                try {
                    const cardExpiry = data.cardExpiry; 
                    const [exp_month, exp_year] = cardExpiry.split("/");
                    const card_details = {
                        number: data?.cardNumber,
                        exp_month: exp_month,
                        exp_year: exp_year,
                        cvc: data?.cvc,
                        default_method: data?.default_method,
                        addressLine1: data?.addressLine1,
                        postalCode: data?.postalCode,
                        addressLine2: data?.addressLine2,
                        city: data?.city,
                        country: data?.country,   
                    }
                    dispatch(addCardPaymentMethod(card_details)).then((resp) => {
                        setLoading(false);
                        if (resp?.payload?.status === 201) {
                            dispatch(success("new payment method added successfully!"));
                            navigate(`/dashboard/payments/manage-payment-methods`);
                        } 
                    });
                } catch (error) {
                    console.error("Error:", error);
                    setLoading(false);
                }
            }
        }
    }


    return (
        <div>
            <div className="pt-24 pb-6 md:px-16 px-8">
                <div
                    className={`dashboard-title ${themeMode === "dark" ? "text-light" : "text-dark"
                        }`}
                >
                    Add Payment Methods
                </div>
                <div
                    className={`dashboard-subtitle py-4 ${themeMode === "dark" ? "text-light" : "text-dark"
                        }`}
                >
                    Choose your preferred payment method.{" "}
                </div>
            </div>
            <div className="md:px-16 xs:px-2 px-8">
                <Toolbar
                    className="paymentMethod-slider"
                    sx={{
                        background: themeMode === "dark" ? "#530000" :"#D05F5F",
                        border:themeMode==="dark"?" 1px solid #FFF":"1px solid #000",
                        display: {md:"flex",sm:"flex",xs:"none"},
                        width: { md:isCustomRange?"75%":"50%", sm: "75%", xs: "100%" },
                        overflowX: { md: "hidden", sm: "auto" },
                    }}
                >
                    <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { display: "none" } }}
                        variant="fullWidth"
                        sx={{
                            width: "100%",
                        }}
                    >
                        {method.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab.label}
                                style={{ minWidth: { md: tab.width * 1.5, sm: tab.width * 0.5 } }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={() => handleMouseLeave(index)}
                                // onClick={() => setSelectedTab(tab.label)}
                                disableRipple
                                className="paymentItem"
                                sx={{
                                    width: "fit-content",
                                    height: "40px !important",
                                    fontSize: { md: "16px", sm: "14px",xs:"12px" },
                                    padding: { md: "0px 25px", sm: "5px 10px" },
                                }}
                            />
                        ))}
                    </Tabs>
                    <Animation {...getAnimationStyle()} />


                </Toolbar>

                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="options"
                        name="options"
                        value={selectedTab} // Set the value of RadioGroup to selectedTab
                        onChange={handleChange}
                        sx={{ display: { md: "none", sm: "none", xs: "flex" }, flexDirection: 'column' }}
                    >
                        {
                            method.map((item, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={item.label} // Set the value to item.label
                                    control={
                                        <Radio
                                            sx={{
                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                '&.Mui-checked': { color: "red" }
                                            }}
                                        />
                                    }
                                    label={item.label}
                                />
                            ))
                        }
                    </RadioGroup>
                </FormControl>
            </div>
            <Grid className="mt-16 md:px-16 px-8" sx={{display:"flex",justifyContent:{md:"flex-start",sm:"flex-start",xs: "space-between"},width:"100%"}}>
                {selectedTab === "Stripe" && (
                    <>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                            <img src={stripeBlue} alt="Stripe" style={{ width: "210px" }} />
                            <Button 
                                variant="contained" 
                                color="primary" 
                                label="Method Type" 
                                onClick={(event) => setAnchorEl(event.currentTarget)} 
                                endIcon={<KeyboardArrowDownIcon />}
                            >
                                Method Options
                            </Button>
                        </Box>
                        <Menu 
                            anchorEl={anchorEl} 
                            open={Boolean(anchorEl)} 
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'bottom', 
                                horizontal: 'right', 
                            }}
                            transformOrigin={{
                                vertical: 'top', 
                                horizontal: 'right', 
                            }}
                        >
                            <MenuItem 
                                onClick={() => {setSelectedMethodOption("Stripe (Credit/Debit Card)"); setAnchorEl(null)}}
                                style={{
                                    backgroundColor:
                                      selectedMethodOption === "Stripe (Credit/Debit Card)" ? "#e0e0e0" : "transparent",
                                }}
                            >
                                Credit/Debit Card
                            </MenuItem>
                            <MenuItem 
                                onClick={() => {setSelectedMethodOption("Stripe (Bank Direct)"); setAnchorEl(null)}}
                                style={{
                                    backgroundColor:
                                      selectedMethodOption === "Stripe (Bank Direct)" ? "#e0e0e0" : "transparent",
                                }}
                            >
                                Bank Direct
                            </MenuItem>
                            <MenuItem 
                                onClick={() => {setSelectedMethodOption("Stripe (SEPA Debit)"); setAnchorEl(null)}}
                                style={{
                                    backgroundColor:
                                      selectedMethodOption === "Stripe (SEPA Debit)" ? "#e0e0e0" : "transparent",
                                }}
                            >
                                SEPA Debit
                            </MenuItem>
                        </Menu>
                    </>
                )}
                {selectedTab === "Paypal" && (
                    <img src={paypal} alt="Paypal" style={{ width: "210px" }} />
                )}
            </Grid>

            <Grid sx={{ width: "100%", display: "flex", flexDirection: "column" }} className="py-12 md:px-16 px-8">

                {
                        selectedTab==="Paypal"?
                        <>
                            <Grid>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                placeholder="Email"
                                                error={!!errors.email}
                                                autoComplete="off"
                                                required
                                                className="payment-method-input"
                                                sx={{
                                                    border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                    background: "transparent",
                                                    width: { md: "40%", sm: "66%", xs: "100%" },
                                                    "& .MuiOutlinedInput-root": {
                                                        background: "transparent",
                                                        "& fieldset": {
                                                            border: "none",
                                                            background: "transparent",
                                                        },
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        background: "transparent",
                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                    },
                                                }}
                                            />
                                            {errors.email && (
                                                <Typography
                                                    variant="body2"
                                                    color="error"
                                                    sx={{ mt: 1, ml: 1 }}
                                                >
                                                    {errors.email.message}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                />
                            </Grid>
                            <Grid my={6}>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                placeholder="Passsword"
                                                error={!!errors.password}
                                                autoComplete="off"
                                                type={showPassword ? "text" : "password"}
                                                required
                                                className="payment-method-input"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                edge="end"
                                                                style={{ color: "#FFF" }}
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOff style={{ color: themeMode === "dark" ? "#FFF" : "#000" }} />
                                                                ) : (
                                                                    <Visibility style={{ color: themeMode === "dark" ? "#FFF" : "#000" }} />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                    background: "transparent",
                                                    width: { md: "40%", sm: "66%", xs: "100%" },
                                                    "& .MuiOutlinedInput-root": {
                                                        background: "transparent",
                                                        "& fieldset": {
                                                            border: "none",
                                                            background: "transparent",
                                                        },
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        background: "transparent",
                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                    },
                                                }}
                                            />
                                            {errors?.password && (
                                                <Typography
                                                    variant="body2"
                                                    color="error"
                                                    sx={{ mt: 1, ml: 1 }}
                                                >
                                                    {errors.password.message}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                />
                            </Grid>
                        </>
                    :
                        selectedTab === "Stripe" ?
                            <>
                                { selectedMethodOption === "Stripe (Credit/Debit Card)" && (
                                    <>
                                        <Grid mb={6}>
                                            <Typography sx={{ fontSize: "20px", fontWeight: "700",color:themeMode==="dark"?"#FFF":"#000" }}>Credit/Debit Card</Typography>
                                        </Grid>
                                        <Grid>
                                            <Controller
                                                name="cardHolderName"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...field}
                                                            label="Card Holder Name"
                                                            // error={!!errors.cardHolderName}
                                                            // helperText={errors.cardHolderName ? errors.cardHolderName.message : ''} 
                                                            size="small"
                                                            autoComplete="off"
                                                            required
                                                            className="payment-method-input"
                                                            sx={{
                                                                border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                                background: "transparent",
                                                                width: { md: "30%", sm: "66%", xs: "100%" },
                                                                "& .MuiOutlinedInput-root": {
                                                                    background: "transparent",
                                                                    "& fieldset": {
                                                                        border: "none",
                                                                        background: "transparent",
                                                                    },
                                                                },
                                                                "& .MuiInputBase-input": {
                                                                    background: "transparent",
                                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                    fontSize: '12px',
                                                                    marginBottom: '15px',
                                                                    transform: 'translate(0px, -20px) scale(1)',
                                                                    '&.MuiInputLabel-shrink': {
                                                                        transform: 'translate(0px, -20px) scale(1)'
                                                                    }
                                                                },
                                                            }}
                                                        />
                                                        {errors.cardHolderName && (
                                                            <FormHelperText error>
                                                                {errors.cardHolderName.message}
                                                            </FormHelperText>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid my={6} sx={{ display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" } }}>
                                            <Controller
                                                name="cardNumber" 
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: { md: "30%", sm: "66%", xs: "100%" },
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                                    fontSize: "12px",
                                                                    marginBottom: "8px",
                                                                }}
                                                            >
                                                                Card Number*
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                                    padding: "8px",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "transparent",
                                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                                    height: "42px",
                                                                }}
                                                            >
                                                                {(!stripe || !elements) ? (
                                                                    <Skeleton
                                                                        variant="rectangular"
                                                                        width="100%"
                                                                        height="100%"
                                                                        sx={{ borderRadius: "4px" }}
                                                                    />
                                                                ) : (
                                                                    <CardNumberElement
                                                                        {...field}
                                                                        options={{
                                                                            showIcon: true,
                                                                            style: {
                                                                                base: {
                                                                                    iconColor: themeMode === "dark" ? "#FFF" : "#000",
                                                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                                                    fontSize: "16px",
                                                                                    "::placeholder": {
                                                                                    color: themeMode === "dark" ? "#AAA" : "#888",
                                                                                    },
                                                                                },
                                                                            },
                                                                        }}
                                                                        onChange={(event) => field.onChange(event.complete ? event : null)}
                                                                    />
                                                                )}
                                                            </Box>
                                                            {errors.cardNumber && (
                                                                <FormHelperText error>
                                                                    {errors.cardNumber.message}
                                                                </FormHelperText>
                                                            )}
                                                        </Box>
                                                    </>
                                                )}
                                            />
                                            <Grid mx={{ md: 3, sm: 6, xs: 0 }} sx={{ display: "flex", gap: { md: 3, sm: 2, xs: 1 }, marginY: { md: "0px", sm: "0px", xs: "40px" }, justifyContent: "space-between", width: { md: "23%", sm: "50%", xs: "100%" } }}>
                                                <Controller
                                                    name="cardExpiry"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: { md: "45%", sm: "45%", xs: "100%" },
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{
                                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                                        fontSize: "12px",
                                                                        marginBottom: "8px",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                    Expiration date* 
                                                                </Typography>
                                                                <Box
                                                                    sx={{
                                                                        border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                                        padding: "8px",
                                                                        borderRadius: "4px",
                                                                        backgroundColor: "transparent",
                                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                                        height: "42px",
                                                                    }}
                                                                >
                                                                    {(!stripe || !elements) ? (
                                                                        <Skeleton
                                                                            variant="rectangular"
                                                                            width="100%"
                                                                            height="100%"
                                                                            sx={{ borderRadius: "4px" }}
                                                                        />
                                                                    ) : (
                                                                        <CardExpiryElement
                                                                            {...field}
                                                                            options={{
                                                                                style: {
                                                                                    base: {
                                                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                                                        fontSize: "16px",
                                                                                        "::placeholder": {
                                                                                            color: themeMode === "dark" ? "#AAA" : "#888",
                                                                                        },
                                                                                    },
                                                                                },
                                                                            }}
                                                                            onChange={(event) => field.onChange(event.complete ? event : null)}
                                                                        />
                                                                    )}
                                                                </Box>
                                                                {errors.cardExpiry && (
                                                                    <FormHelperText error>
                                                                        {errors.cardExpiry.message}
                                                                    </FormHelperText>
                                                                )}
                                                            </Box>
                                                        </>
                                                    )}
                                                />
                                                <Controller
                                                    name="cvc"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: { md: "45%", sm: "45%", xs: "100%" },
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{
                                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                                        fontSize: "12px",
                                                                        marginBottom: "8px",
                                                                    }}
                                                                >
                                                                    CVC*
                                                                </Typography>
                                                                <Box
                                                                    sx={{
                                                                        border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                                        padding: "8px",
                                                                        borderRadius: "4px",
                                                                        backgroundColor: "transparent",
                                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                                        height: "42px",
                                                                    }}
                                                                >
                                                                    {(!stripe || !elements) ? (
                                                                        <Skeleton
                                                                            variant="rectangular"
                                                                            width="100%"
                                                                            height="100%"
                                                                            sx={{ borderRadius: "4px" }}
                                                                        />
                                                                    ) : (
                                                                        <CardCvcElement
                                                                            {...field}
                                                                            options={{
                                                                                style: {
                                                                                    base: {
                                                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                                                        fontSize: "16px",
                                                                                        "::placeholder": {
                                                                                            color: themeMode === "dark" ? "#AAA" : "#888",
                                                                                        },
                                                                                    },
                                                                                },
                                                                            }}
                                                                            onChange={(event) => field.onChange(event.complete ? event : null)}
                                                                        />
                                                                    )}
                                                                </Box>
                                                                {errors.cvc && (
                                                                    <FormHelperText error>
                                                                        {errors.cvc.message}
                                                                    </FormHelperText>
                                                                )}
                                                            </Box>
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                { selectedMethodOption === "Stripe (Credit/Debit Card) New" && (
                                    <>
                                        <Grid>
                                            <Controller
                                                name="cardHolderName"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            {...field}  
                                                            label="Card Holder Name"
                                                            error={!!errors.cardHolderName}
                                                            // helperText={errors.cardHolderName ? errors.cardHolderName.message : ''} 
                                                            required
                                                            size="small"
                                                            autoComplete="off"
                                                            className="payment-method-input"
                                                            sx={{
                                                                border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                                background: "transparent",
                                                                width: { md: "30%", sm: "66%", xs: "100%" },
                                                                "& .MuiOutlinedInput-root": {
                                                                    background: "transparent",
                                                                    "& fieldset": {
                                                                        border: "none",
                                                                        background: "transparent",
                                                                    },
                                                                },
                                                                "& .MuiInputBase-input": {
                                                                    background: "transparent",
                                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                fontSize: '12px',
                                                                marginBottom: '15px',
                                                                transform: 'translate(0px, -20px) scale(1)',
                                                                '&.MuiInputLabel-shrink': {
                                                                    transform: 'translate(0px, -20px) scale(1)'
                                                                },
                                                                '&.Mui-error': {  
                                                                    color: '#d32f2f',
                                                                },
                                                                },
                                                            }}
                                                        />
                                                        {errors.cardHolderName && (
                                                            <FormHelperText error>
                                                                {errors.cardHolderName.message}
                                                            </FormHelperText>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid my={6} sx={{display:"flex",flexDirection:{md:"row",sm:"row",xs:"column"}}}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: { md: "30%", sm: "66%", xs: "100%" },
                                                }}
                                            >
                                                <Controller
                                                    name="cardNumber"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <TextField
                                                                {...field}
                                                                label="Card Number"
                                                                placeholder="4242424242424242"
                                                                size="small"
                                                                error={!!errors.cardNumber}
                                                                // helperText={errors.cardNumber ? errors.cardNumber.message : ''} 
                                                                required
                                                                autoComplete="off"
                                                                inputProps={{ maxLength: 16 }}
                                                                className="payment-method-input"
                                                                sx={{
                                                                    border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                                    background: "transparent",
                                                                    width: '100%',
                                                                    "& .MuiOutlinedInput-root": {
                                                                        background: "transparent",
                                                                        "& fieldset": {
                                                                            border: "none",
                                                                            background: "transparent",
                                                                        },
                                                                    },
                                                                    "& .MuiInputBase-input": {
                                                                        background: "transparent",
                                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                    fontSize: '12px',
                                                                    marginBottom: '15px',
                                                                    transform: 'translate(0px, -20px) scale(1)',
                                                                    '&.MuiInputLabel-shrink': {
                                                                        transform: 'translate(0px, -20px) scale(1)'
                                                                    },
                                                                    '&.Mui-error': {  
                                                                        color: '#d32f2f',
                                                                    },
                                                                    },
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors.cardNumber && (
                                                    <FormHelperText error>
                                                        {errors.cardNumber.message}
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                            <Grid mx={{md:3,sm:6,xs:0}} sx={{display:"flex",marginY:{md:"0px",sm:"0px",xs:"40px"},justifyContent:"space-between",width:{md:"23%",sm:"50%",xs:"100%"}}}>
                                                <Box
                                                     sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: { md: "45%", sm: "45%", xs: "45%" },
                                                    }}
                                                >
                                                    <Controller
                                                        name="cardExpiry"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    label="Expiration date"
                                                                    placeholder="mm/yy"
                                                                    size="small"
                                                                    autoComplete="off"
                                                                    error={!!errors.cardExpiry}
                                                                    // helperText={errors.cardExpiry ? errors.cardExpiry.message : ''} 
                                                                    required
                                                                    className="payment-method-input" 
                                                                    inputProps={{
                                                                        maxLength: 5, 
                                                                    }}        
                                                                    sx={{
                                                                        border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                                        background: "transparent",
                                                                        width: '100%',
                                                                        "& .MuiOutlinedInput-root": {
                                                                            background: "transparent",
                                                                            "& fieldset": {
                                                                                border: "none",
                                                                                background: "transparent",
                                                                            },
                                                                        },
                                                                        "& .MuiInputBase-input": {
                                                                            background: "transparent",
                                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                                        },
                                                                        '& .MuiInputLabel-root': {
                                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                            fontSize: '12px',
                                                                            marginBottom: '15px',
                                                                            transform: 'translate(0px, -20px) scale(1)',
                                                                            '&.MuiInputLabel-shrink': {
                                                                                transform: 'translate(0px, -20px) scale(1)'
                                                                            },
                                                                            '&.Mui-error': {  
                                                                                color: '#d32f2f',
                                                                            },
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                    {errors.cardExpiry && (
                                                        <FormHelperText error>
                                                            {errors.cardExpiry.message}
                                                        </FormHelperText>
                                                    )}
                                                </Box>
                                                <Box
                                                     sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: { md: "45%", sm: "45%", xs: "45%" },
                                                    }}
                                                >
                                                    <Controller
                                                        name="cvc"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    label="CVC"
                                                                    placeholder="***"
                                                                    size="small"
                                                                    autoComplete="off"
                                                                    error={!!errors.cvc}
                                                                    // helperText={errors.cvc ? errors.cvc.message : ''} 
                                                                    required
                                                                    inputProps={{ maxLength: 3 }}
                                                                    className="payment-method-input"
                                                                    sx={{
                                                                        border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                                        background: "transparent",
                                                                        width: '100%',
                                                                        "& .MuiOutlinedInput-root": {
                                                                            background: "transparent",
                                                                            "& fieldset": {
                                                                                border: "none",
                                                                                background: "transparent",
                                                                            },
                                                                        },
                                                                        "& .MuiInputBase-input": {
                                                                            background: "transparent",
                                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                                        },
                                                                        '& .MuiInputLabel-root': {
                                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                            fontSize: '12px',
                                                                            marginBottom: '15px',
                                                                            transform: 'translate(0px, -20px) scale(1)',
                                                                            '&.MuiInputLabel-shrink': {
                                                                                transform: 'translate(0px, -20px) scale(1)'
                                                                            },
                                                                            '&.Mui-error': {  
                                                                                color: '#d32f2f',
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                                
                                                            </>
                                                        )}
                                                    />
                                                    {errors.cvc && (
                                                        <FormHelperText error>
                                                            {errors.cvc.message}
                                                        </FormHelperText>
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                { selectedMethodOption === "Stripe (Bank Direct)" && (
                                    <>
                                        <Grid mb={6}>
                                            <Typography sx={{ fontSize: "20px", fontWeight: "700",color:themeMode==="dark"?"#FFF":"#000" }}>Bank Direct (U.S.)</Typography>
                                        </Grid>
                                        <Grid sx={{ display: "flex", gap: 3 }}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: { md: "30%", sm: "50%", xs: "100%" },
                                                }}
                                            >
                                                <Controller
                                                    name="account_number"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <TextField
                                                                {...field}
                                                                label="Account Number"
                                                                error={!!errors.account_number}
                                                                size="small"
                                                                autoComplete="off"
                                                                required
                                                                className="payment-method-input"
                                                                sx={{
                                                                    border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                                    background: "transparent",
                                                                    width: "100%",
                                                                    "& .MuiOutlinedInput-root": {
                                                                        background: "transparent",
                                                                        "& fieldset": {
                                                                            border: "none",
                                                                            background: "transparent",
                                                                        },
                                                                    },
                                                                    "& .MuiInputBase-input": {
                                                                        background: "transparent",
                                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                        color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                        fontSize: '12px',
                                                                        marginBottom: '15px',
                                                                        transform: 'translate(0px, -20px) scale(1)',
                                                                        '&.MuiInputLabel-shrink': {
                                                                            transform: 'translate(0px, -20px) scale(1)'
                                                                        },
                                                                        "&.Mui-error": {
                                                                          color: "#d32f2f",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors.account_number && (
                                                    <FormHelperText error>
                                                        {errors.account_number.message}
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: { md: "30%", sm: "50%", xs: "100%" },
                                                }}
                                            >
                                                <Controller
                                                    name="routing_number"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <TextField
                                                                {...field}
                                                                label="Routing Number"
                                                                error={!!errors.routing_number}
                                                                size="small"
                                                                autoComplete="off"
                                                                required
                                                                className="payment-method-input"
                                                                sx={{
                                                                    border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                                    background: "transparent",
                                                                    width: "100%",
                                                                    "& .MuiOutlinedInput-root": {
                                                                        background: "transparent",
                                                                        "& fieldset": {
                                                                            border: "none",
                                                                            background: "transparent",
                                                                        },
                                                                    },
                                                                    "& .MuiInputBase-input": {
                                                                        background: "transparent",
                                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                        color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                        fontSize: '12px',
                                                                        marginBottom: '15px',
                                                                        transform: 'translate(0px, -20px) scale(1)',
                                                                        '&.MuiInputLabel-shrink': {
                                                                            transform: 'translate(0px, -20px) scale(1)'
                                                                        },
                                                                        "&.Mui-error": {
                                                                          color: "#d32f2f",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors.routing_number && (
                                                    <FormHelperText error>
                                                        {errors.routing_number.message}
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid my={6} sx={{ display: "flex", gap: 3 }}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: { md: "30%", sm: "50%", xs: "100%" },
                                                }}
                                            >
                                                <Controller
                                                    name="account_holder_name"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <TextField
                                                                {...field}
                                                                label="Account Holder Name"
                                                                error={!!errors.account_holder_name}
                                                                size="small"
                                                                autoComplete="off"
                                                                required
                                                                className="payment-method-input"
                                                                sx={{
                                                                    border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                                    background: "transparent",
                                                                    width: "100%",
                                                                    "& .MuiOutlinedInput-root": {
                                                                        background: "transparent",
                                                                        "& fieldset": {
                                                                            border: "none",
                                                                            background: "transparent",
                                                                        },
                                                                    },
                                                                    "& .MuiInputBase-input": {
                                                                        background: "transparent",
                                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                        color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                        fontSize: '12px',
                                                                        marginBottom: '15px',
                                                                        transform: 'translate(0px, -20px) scale(1)',
                                                                        '&.MuiInputLabel-shrink': {
                                                                            transform: 'translate(0px, -20px) scale(1)'
                                                                        },
                                                                        "&.Mui-error": {
                                                                          color: "#d32f2f",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors.account_holder_name && (
                                                    <FormHelperText error>
                                                        {errors.account_holder_name.message}
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: { md: "30%", sm: "50%", xs: "100%" },
                                                }}
                                            >
                                                <Controller
                                                    name="account_holder_type"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <FormControl
                                                                size="small"
                                                                error={!!errors.account_holder_type}
                                                                sx={{
                                                                border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                                background: "transparent",
                                                                width: "100%",
                                                                "& .MuiOutlinedInput-root": {
                                                                    background: "transparent",
                                                                    "& fieldset": {
                                                                    border: "none",
                                                                    background: "transparent",
                                                                    },
                                                                },
                                                                "& .MuiInputBase-input": {
                                                                    background: "transparent",
                                                                    color: themeMode === "dark" ? "#FFF" : "#000",
                                                                },
                                                                "& .MuiInputLabel-root": {
                                                                    color: themeMode === "dark" ? "#ffff" : "#000",
                                                                    fontSize: "12px",
                                                                    marginBottom: "15px",
                                                                    transform: "translate(0px, -20px) scale(1)",
                                                                    "&.MuiInputLabel-shrink": {
                                                                    transform: "translate(0px, -20px) scale(1)",
                                                                    },
                                                                    "&.Mui-error": {
                                                                      color: "#d32f2f",
                                                                    },
                                                                },
                                                                }}
                                                            >
                                                                <InputLabel id="account-holder-type-label">Account Holder Type *</InputLabel>
                                                                <Select
                                                                    {...field}
                                                                    labelId="account-holder-type-label"
                                                                    value={field.value || ""}
                                                                    required
                                                                >
                                                                    <MenuItem value="individual">Individual</MenuItem>
                                                                    <MenuItem value="company">Company</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </>
                                                    )}
                                                />
                                                {errors.account_holder_type && (
                                                    <FormHelperText error>{errors.account_holder_type.message}</FormHelperText>
                                                )}
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                                { selectedMethodOption === "Stripe (SEPA Debit)" && (
                                    <>
                                        <Grid mb={6}>
                                            <Typography sx={{ fontSize: "20px", fontWeight: "700",color:themeMode==="dark"?"#FFF":"#000" }}>SEPA Debit (Europe)</Typography>
                                        </Grid>
                                        <Grid mb={6} sx={{ display: "flex", gap: 3 }}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: { md: "30%", sm: "50%", xs: "100%" },
                                                }}
                                            >
                                                <Controller
                                                    name="iban"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <TextField
                                                                {...field}
                                                                label="Iban"
                                                                error={!!errors.iban}
                                                                size="small"
                                                                autoComplete="off"
                                                                required
                                                                className="payment-method-input"
                                                                sx={{
                                                                    border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                                    background: "transparent",
                                                                    width: "100%",
                                                                    "& .MuiOutlinedInput-root": {
                                                                        background: "transparent",
                                                                        "& fieldset": {
                                                                            border: "none",
                                                                            background: "transparent",
                                                                        },
                                                                    },
                                                                    "& .MuiInputBase-input": {
                                                                        background: "transparent",
                                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                        color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                        fontSize: '12px',
                                                                        marginBottom: '15px',
                                                                        transform: 'translate(0px, -20px) scale(1)',
                                                                        '&.MuiInputLabel-shrink': {
                                                                            transform: 'translate(0px, -20px) scale(1)'
                                                                        },
                                                                        "&.Mui-error": {
                                                                          color: "#d32f2f",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors.iban && (
                                                    <FormHelperText error>
                                                        {errors.iban.message}
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: { md: "30%", sm: "50%", xs: "100%" },
                                                }}
                                            >
                                                <Controller
                                                    name="account_holder_name"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <TextField
                                                                {...field}
                                                                label="Account Holder Name"
                                                                error={!!errors.account_holder_name}
                                                                size="small"
                                                                autoComplete="off"
                                                                required
                                                                className="payment-method-input"
                                                                sx={{
                                                                    border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                                    background: "transparent",
                                                                    width: "100%",
                                                                    "& .MuiOutlinedInput-root": {
                                                                        background: "transparent",
                                                                        "& fieldset": {
                                                                            border: "none",
                                                                            background: "transparent",
                                                                        },
                                                                    },
                                                                    "& .MuiInputBase-input": {
                                                                        background: "transparent",
                                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                        color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                        fontSize: '12px',
                                                                        marginBottom: '15px',
                                                                        transform: 'translate(0px, -20px) scale(1)',
                                                                        '&.MuiInputLabel-shrink': {
                                                                            transform: 'translate(0px, -20px) scale(1)'
                                                                        },
                                                                        "&.Mui-error": {
                                                                          color: "#d32f2f",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors.account_holder_name && (
                                                    <FormHelperText error>
                                                        {errors.account_holder_name.message}
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                                <Grid mb={6}>
                                    <Typography sx={{ fontSize: "20px", fontWeight: "700",color:themeMode==="dark"?"#FFF":"#000" }}>Billing Address (Optional)</Typography>
                                </Grid>
                                <Grid sx={{ display: "flex" }}>
                                    <Controller
                                        name="addressLine1"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Address Line 1"
                                                    error={!!errors.addressLine1}
                                                    size="small"
                                                    autoComplete="off"
                                                    className="payment-method-input"
                                                    sx={{
                                                        border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                        background: "transparent",
                                                        width: { md: "30%", sm: "80%", xs: "100%" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                    <Controller
                                        name="postalCode"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Postal Code"
                                                    size="small"
                                                    autoComplete="off"
                                                    error={!!errors.postalCode}
                                                    className="payment-method-input mx-6"
                                                    sx={{
                                                        border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                        display: { md: "flex", sm: "none", xs: "none" },
                                                        background: "transparent",
                                                        width: { md: "15%", sm: "20%", xs: "100%" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid my={6} sx={{ display: "flex", flexDirection: { md: "row", sm: "column", xs: "column" } }}>
                                    <Controller
                                        name="addressLine2"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Address Line 2"
                                                    size="small"
                                                    error={!!errors.addressLine2}
                                                    autoComplete="off"
                                                    className="payment-method-input"
                                                    sx={{
                                                        border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                        background: "transparent",
                                                        width: { md: "30%", sm: "80%", xs: "100%" },
                                                        marginBottom: { md: "0px", sm: "40px", xs: "15px" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                    <Grid mx={{ md: 3, sm: 0, xs: 0 }} my={{ md: 0, sm: 3, xs: 3 }} sx={{ display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }, marginY: { md: "0px", sm: "0px", xs: "40px" }, justifyContent: "space-between", width: { md: "35%", sm: "100%", xs: "100%" } }}>
                                        <Controller
                                            name="city"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <TextField
                                                        {...field}
                                                        label="City"
                                                        size="small"
                                                        error={!!errors.city}
                                                        autoComplete="off"
                                                        className="payment-method-input"
                                                        sx={{
                                                            border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                            background: "transparent",
                                                            width: { md: "45%", sm: "45%", xs: "100%" },
                                                            "& .MuiOutlinedInput-root": {
                                                                background: "transparent",
                                                                "& fieldset": {
                                                                    border: "none",
                                                                    background: "transparent",
                                                                },
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                background: "transparent",
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                fontSize: '12px',
                                                                marginBottom: '15px',
                                                                transform: 'translate(0px, -20px) scale(1)',
                                                                '&.MuiInputLabel-shrink': {
                                                                    transform: 'translate(0px, -20px) scale(1)'
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                        <Controller
                                            name="country"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <TextField
                                                        {...field}
                                                        label="Country"
                                                        error={!!errors.country}
                                                        size="small"
                                                        autoComplete="off"
                                                        className="payment-method-input"
                                                        sx={{
                                                            border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                            background: "transparent",
                                                            width: { md: "45%", sm: "45%", xs: "100%" },
                                                            marginX: { md: 3, sm: 3, xs: 0 },
                                                            marginY: { md: 0, sm: 0, xs: 4 },
                                                            "& .MuiOutlinedInput-root": {
                                                                background: "transparent",
                                                                "& fieldset": {
                                                                    border: "none",
                                                                    background: "transparent",
                                                                },
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                background: "transparent",
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                fontSize: '12px',
                                                                marginBottom: '15px',
                                                                transform: 'translate(0px, -20px) scale(1)',
                                                                '&.MuiInputLabel-shrink': {
                                                                    transform: 'translate(0px, -20px) scale(1)'
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                    :
                    ""
                }
                    
                
                <Grid className={themeMode==="dark"?"text-light":"text-dark"}>
                <Controller
                    name="default_method"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value, ref } }) => (
                        <FormControlLabel
                            sx={{ color: themeMode === "dark" ? "#FFF" : "#000" }}
                            control={
                                <Checkbox
                                    inputRef={ref}
                                    size="large"
                                    checked={value || ''} 
                                    onChange={(event) => {
                                        onChange(event.target.checked); 
                                    }}
                                    sx={{
                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                        "&.Mui-checked": {
                                            color: themeMode === "dark" ? "#FFF" : "#000"
                                        },
                                    }}
                                />
                            }
                            label="Set as primary payment method"
                        />
                    )}
                />
                </Grid>
                <div className="pt-10">
                    <Button
                        variant="contained"
                        size="large"
                        style={{
                            backgroundColor: "#E50000",
                            color: "white",
                            borderRadius: "30px",
                            border:"1px solid #FFF"
                        }}
                        disabled={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        <span className="sign-button">{loading ? "Loading..." : "Add payment method"}</span>
                    </Button>
                    <Typography className={`pt-6 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
                        Payment information is encrypted and secure. Learn more about our{" "}
                        <Button disableRipple onClick={() => (navigate("/privacy-policy"))} 
                        sx={{padding:"0px",minWidth:"0px",textTransform:"none", fontSize: "18px", fontStyle: "italic", fontWeight: "700", color: themeMode === "dark" ? "#FFF" : "#000",textDecoration:"none","&:hover":{background:"transparent"} }}>
                            Privacy Policy.
                        </Button>
                    </Typography>

                </div>
            </Grid>
        </div>
    )
}
export default AddPaymentMethod;