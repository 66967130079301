import { Button, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setService } from "../../store/homeSlice";
export const OurServiceCard = ({ title, description, bgImage, img }) => {
  const dispatch = useDispatch();
  return (
    <div
      className="  h-full"
      style={{
        background: "linear-gradient(0deg, #000 0%, transparent 100%)",
        maxWidth: "290px",
        border: "2px solid white",
        borderRadius: "17px",
      }}
    >
      <div
        className="flex flex-col justify-between h-full items-center h-full p-6"
        style={
          {
            // background: "linear-gradient(0deg, #000 0%, transparent 100%)",
          }
        }
      >
        <div className="">
          <img src={img} alt=" icon" />
        </div>
        <div className="team-member-name text-center flex w-full justify-center my-4">
          {title}
        </div>
        <div className="team-member-designation flex w-full justify-center px-8">
          {description}
        </div>
        <Link to={`/services/${title}`}>
          {" "}
          <Button
            variant="contained"
            className="primary-button mt-6"
            onClick={() =>
              dispatch(
                setService({
                  title: title,
                  description: description,
                })
              )
            }
            sx={{
              textWrap: "nowrap",
              width: { md: "fit-content", sm: "fit-content", xs: "100%" },
            }}
          >
            <span className=" text-white" style={{ fontSize: "14px" }}>
              Learn more
            </span>
          </Button>
        </Link>
      </div>
    </div>
  );
};
