import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Popper,
  Select,
  styled,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { MessageTabs } from "./MessageTabs";
import StepperStages from "./StepperStages";
import {
  ArrowBackIos,
  ArrowBackIosNewRounded,
  ArrowBackOutlined,
  ArrowForwardOutlined,
  ExpandMore,
  LinkedIn,
  SearchOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import {
  getChat,
  getInboxMessages,
  getNotes,
  getProspectInfo,
  setChatTags,
  setReminder,
  setSelectedIndex,
  setShowMessageReminder,
} from "../../../store/messagesSlice";
import { success } from "../../../store/alertSlice";
import { useEffect } from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";
import { resumeAutomation, stopAutomation } from "../../../store/campaignSlice";
import { Link, useLocation } from "react-router-dom";
import { getQuickResponses } from "../../../store/settingsSlice";

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    backgroundColor: "red",
    color: "white",
  },
});

const defaultValues = {
  title: "",
  campaign_type: "prospecting",
};

const schema = yup
  .object()
  .shape({
    title: yup.string().required("You must enter title"),
    campaign_type: yup.string().required("You must select campaign type"),
  })
  .required();

export default function MessageDialog({
  openMessageShow,
  closeMessageShow,
  messageId,
  handleClickOpenProfileSummary,
  handleClickOpenSign,
}) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const openedChat = useSelector((state) => state.messages.openedChat);
  const show_reminder = useSelector((state) => state.messages.show_reminder);
  const selectedIndex = useSelector((state) => state.messages.selectedIndex);
  const inboxMessages = useSelector((state) => state.messages.inboxMessages);
  const messagesLoading = useSelector(
    (state) => state.messages.messagesLoading
  );

  const location = useLocation();
  const isMessagesUrl = location.pathname.includes("messages");

  const tags = useSelector((state) => state.settings.tags?.data);
  const [tagsUuids, setTagsUuids] = useState(openedChat ? openedChat.tags : []);
  const [showReminder, setShowReminder] = useState(show_reminder);
  const [selectedDate, setSelectedDate] = useState(
    dayjs(openedChat?.reminder_at)
  );

  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(
      getQuickResponses({
        page: 1,
        per_page: 50,
      })
    );
    setTagsUuids(openedChat?.tags);
    setSelectedDate(dayjs(openedChat?.reminder_at));
    setShowReminder(false);
    dispatch(setShowMessageReminder(false));
    // dispatch(getNotes(openedChat?.uuid));
  }, [openedChat]);

  const handleChangeTags = (event, newValue) => {
    setTagsUuids(newValue);
    dispatch(
      setChatTags({
        id: openedChat.uuid,
        tag_uuids: newValue.map((tag) => tag.uuid),
      })
    ).then((resp) => {
      if (resp?.payload?.status === 200) {
        dispatch(getInboxMessages());
        dispatch(success("Tag Added"));
      }
    });

    console.log("Selected Objects:", newValue);
  };

  const { errors } = formState;

  function onSubmit(data) {}
  return (
    <Backdrop>
      <Dialog
        open={openMessageShow}
        onClose={closeMessageShow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          backdropFilter: "blur(15px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            paddingBottom: "12px",
            boxShadow: "none",
            // maxHeight: "800px",
            maxHeight: {
              // xs: "16px",
              sm: "full",
              md: "800px",
              lg: "800px",
            },
          },
        }}
        // fullScreen
        fullWidth
        maxWidth={"xl"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer"
          onClick={closeMessageShow}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            className="mb-2"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke="white"
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent className="p-0">
          <DialogContentText
            id="alert-dialog-description"
            className={`lg:mx-8 ${
              themeMode === "dark"
                ? "message-dialog-layout"
                : "message-dialog-layout-light"
            }`}
          >
            <div className="lg:flex w-full">
              <div
                className="lg:w-3/4"
                style={{ borderRight: "2px solid white" }}
              >
                {isMessagesUrl && (
                  <div className="px-8 pt-2 flex items-center justify-between text-white cursor-pointer">
                    <Tooltip title="Previous chat">
                      {" "}
                      <ArrowBackOutlined
                        onClick={() => {
                          if (selectedIndex > 0) {
                            dispatch(setSelectedIndex(selectedIndex - 1));
                            dispatch(
                              getChat(inboxMessages[selectedIndex - 1].uuid)
                            );
                          } else {
                          }
                        }}
                        sx={{ color: selectedIndex < 1 ? "gray" : "white" }}
                      />
                    </Tooltip>
                    <span className="flex items-center space-x-2">
                      {selectedIndex + 1} of {inboxMessages.length}{" "}
                      {messagesLoading && (
                        <CircularProgress
                          className="ml-2"
                          sx={{ color: "white" }}
                          size={16}
                        />
                      )}
                    </span>

                    <Tooltip title="Next chat">
                      <ArrowForwardOutlined
                        onClick={() => {
                          if (selectedIndex + 1 < inboxMessages.length) {
                            dispatch(setSelectedIndex(selectedIndex + 1));
                            dispatch(
                              getChat(inboxMessages[selectedIndex + 1].uuid)
                            );
                          } else {
                          }
                        }}
                        sx={{
                          color:
                            selectedIndex + 1 == inboxMessages.length
                              ? "gray"
                              : "white",
                        }}
                      />{" "}
                    </Tooltip>
                  </div>
                )}

                <MessageTabs />
              </div>
              <div
                className="w-full lg:w-1/4 pt-8"
                // style={{ maxHeight: "510px", overflowY: "auto" }}
              >
                <Box
                  sx={{
                    maxHeight: {
                      xs: "full", // Extra small devices
                      sm: "full", // Small devices
                      md: "510px", // Medium devices
                      lg: "510px", // Large devices
                      xl: "510px", // Extra large devices
                    },

                    overflowY: "auto",
                  }}
                >
                  <div className="flex flex-col justify-center items-center">
                    <Avatar
                      sx={{ width: 81, height: 81 }}
                      src={openedChat?.prospect?.profile_picture_url}
                      alt={"user_dp"}
                    />
                    <div
                      className={
                        themeMode === "dark"
                          ? "message-sender pt-2"
                          : "message-sender-light pt-2"
                      }
                    >
                      <Link to={openedChat?.prospect?.profile_url}>
                        <IconButton target="_blank">
                          <LinkedIn
                            sx={{
                              color: themeMode === "dark" ? "#FFF" : "#FFF",
                            }}
                          />
                        </IconButton>
                      </Link>{" "}
                      {openedChat?.prospect?.name}
                    </div>
                    <div
                      className={
                        themeMode === "dark"
                          ? "message-sender-position px-1"
                          : "message-sender-position px-1"
                      }
                    >
                      {openedChat?.prospect?.current_job_title} @{" "}
                      {openedChat?.prospect?.current_company}{" "}
                      <div className="pt-1">
                        Connection of{" "}
                        <span
                          style={{
                            color: "#BDFF00",
                            textTransform: "none",
                            fontStyle: "italic",
                          }}
                        >
                          <b>
                            {" "}
                            {
                              openedChat?.prospect?.connection
                                ?.connection_owner_name
                            }
                          </b>
                        </span>
                      </div>
                    </div>
                    <StepperStages />
                  </div>
                  <Box className="center-box  ">
                    <Button
                      className="step-label"
                      style={{
                        color: "white",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setShowReminder(true);
                        dispatch(setShowMessageReminder(true));
                      }}
                    >
                      {openedChat?.reminder_at
                        ? moment(openedChat.reminder_at).format(
                            "dddd, DD MM YYYY hh:mmA"
                          )
                        : "Set Reminder"}
                    </Button>
                    {show_reminder && (
                      <div className="w-full flex justify-center items-center">
                        {" "}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            className={
                              themeMode === "dark"
                                ? "custom-datetime-picker"
                                : "custom-datetime-picker"
                            }
                          >
                            <DateTimePicker
                              placeholder="Start Time"
                              value={selectedDate}
                              onChange={(newValue) => {
                                setSelectedDate(newValue);
                              }}
                              onAccept={(newValue) => {
                                setSelectedDate(newValue);
                                dispatch(
                                  setReminder({
                                    id: openedChat.uuid,
                                    reminder_at: moment(newValue?.$d).format(
                                      "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                    ),
                                  })
                                ).then((resp) => {
                                  if (resp?.payload?.status === 200) {
                                    setShowReminder(false);
                                    dispatch(setShowMessageReminder(false));

                                    dispatch(getChat(openedChat.uuid));
                                    dispatch(success("Reminder set"));
                                  }
                                });
                                setShowReminder(false);
                                dispatch(setShowMessageReminder(false));
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    sx: {
                                      "& fieldset": {
                                        border: "none", // Remove the border here
                                      },
                                    },
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        border: "none",
                                        color:
                                          themeMode === "dark"
                                            ? "white"
                                            : "white",
                                      },
                                    },
                                  }}
                                  className={
                                    themeMode === "dark"
                                      ? "meetings-time-field"
                                      : "meetings-time-field"
                                  }
                                  {...params}
                                />
                              )}
                              PopperProps={{
                                component: StyledPopper, // Apply custom styles for the popper
                              }}
                            />
                          </Box>
                        </LocalizationProvider>
                      </div>
                    )}

                    <Button
                      className="step-label"
                      style={{
                        color: "white",
                        textTransform: "none",
                        fontSize: "15px",
                        color:
                          openedChat?.prospect?.automation_enabled === true
                            ? "#BDFF00"
                            : "green",
                      }}
                      onClick={() =>
                        openedChat?.prospect?.automation_enabled === true ? (
                          <>
                            {dispatch(
                              stopAutomation(openedChat?.prospect?.uuid)
                            ).then((res) => {
                              if (res?.payload?.status === 200) {
                                dispatch(success("Automation Stopped"));
                                dispatch(getChat(openedChat.uuid));
                              }
                            })}
                          </>
                        ) : (
                          <>
                            {dispatch(
                              resumeAutomation(openedChat?.prospect?.uuid)
                            ).then((res) => {
                              if (res?.payload?.status === 200) {
                                dispatch(success("Automation Resumed"));
                                dispatch(getChat(openedChat.uuid));
                              }
                            })}
                          </>
                        )
                      }
                    >
                      {openedChat?.prospect?.automation_enabled === true
                        ? "Stop Automation"
                        : "Resume Automation"}
                    </Button>
                    <Button
                      className="step-label"
                      style={{
                        color: "white",
                        textTransform: "none",
                        fontSize: "15px",
                        color: "#BDFF00",
                      }}
                      onClick={() => {
                        handleClickOpenSign();
                      }}
                    >
                      Customize Signature
                    </Button>
                  </Box>

                  <Accordion
                    sx={{
                      padding: 0,
                      backgroundColor: "transparent",
                      color: themeMode === "dark" ? "#fff" : "#000",
                      boxShadow: "none",
                      borderBottom: "none",
                      "&:before": {
                        display: "none",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMore
                          sx={{ color: themeMode === "dark" ? "#fff" : "#fff" }}
                        />
                      }
                    >
                      <div
                        className={`px-6 ${
                          themeMode === "dark"
                            ? "prospect-info"
                            : "prospect-info "
                        }`}
                      >
                        Prospect Info
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        margin: 0, // Remove padding
                      }}
                    >
                      <div className=" -mt-2 px-6 -mb-6">
                        <div className="flex items-center space-x-1">
                          <img src="/Portrait.png" alt="portrait" />
                          <div className="prospect-info-det">
                            {" "}
                            {openedChat?.prospect?.current_job_title}
                          </div>
                        </div>
                        <div className="flex items-center space-x-1 pt-2">
                          <img src="/Company.png" alt="Company" />
                          <div className="prospect-info-det">
                            {openedChat?.prospect?.current_company}
                          </div>
                        </div>
                        <div className="flex items-center space-x-1 pt-2">
                          <img src="/Letter.png" alt="Letter" />
                          <div className="prospect-info-det">
                            {" "}
                            {openedChat?.prospect?.email
                              ? openedChat?.prospect?.email
                              : "Email not avaialble"}
                          </div>
                        </div>
                        <div className="flex items-center space-x-1 pt-2">
                          <img src="/Phone.png" alt="Phone" />
                          <div className="prospect-info-det">
                            {" "}
                            {openedChat?.prospect?.phone_number
                              ? openedChat?.prospect?.phone_number
                              : "Phone not avaialble"}
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    sx={{
                      padding: 0,
                      backgroundColor: "transparent",
                      color: themeMode === "dark" ? "#fff" : "#000",
                      boxShadow: "none",
                      borderBottom: "none",
                      "&:before": {
                        display: "none",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMore
                          sx={{ color: themeMode === "dark" ? "#fff" : "#fff" }}
                        />
                      }
                    >
                      <div
                        className={`px-6 ${
                          themeMode === "dark"
                            ? "prospect-info"
                            : "prospect-info "
                        }`}
                      >
                        Tags
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        margin: 0, // Remove padding
                      }}
                    >
                      <div className=" -mt-6 px-2 -mb-6">
                        <Autocomplete
                          multiple
                          freeSolo
                          disablePortal
                          value={tagsUuids}
                          options={tags}
                          getOptionLabel={(option) => option.title}
                          onChange={handleChangeTags}
                          sx={{ width: 300 }}
                          renderTags={(selected, getTagProps) =>
                            selected.map((option, index) => (
                              <Chip
                                key={index}
                                label={`#${option.title}`}
                                {...getTagProps({ index })}
                                className="prospect-info-det"
                                sx={{
                                  backgroundColor:
                                    themeMode === "dark"
                                      ? "transparent"
                                      : "transparent",
                                  // color: themeMode === "dark" ? "white" : "black",
                                  "& .MuiChip-deleteIcon": {
                                    color: "#BDFF00",
                                  },
                                }}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              placeholder="Add Tag"
                              {...params}
                              size="small"
                              sx={{
                                "& fieldset": {
                                  border: "none",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "transparent", // Change border color on hover
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "transparent", // Change border color on hover
                                  },
                                },
                                "&:hover .MuiInput-notched": {
                                  borderColor: "transparent",
                                },
                                "&.Mui-focused .MuiTextFieldInput-notched": {
                                  borderColor: "transparent",
                                },
                                "& .MuiInputBase-input": {
                                  color:
                                    themeMode === "dark"
                                      ? "#BDFF00"
                                      : "#BDFF00",
                                },
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                  >
                                    <SearchOutlined
                                      style={{
                                        color:
                                          themeMode === "dark"
                                            ? "white"
                                            : "black",
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          )}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      padding: 0,
                      backgroundColor: "transparent",
                      color: themeMode === "dark" ? "#fff" : "#000",
                      boxShadow: "none",
                      borderBottom: "none",
                      "&:before": {
                        display: "none",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMore
                          sx={{ color: themeMode === "dark" ? "#fff" : "#fff" }}
                        />
                      }
                    >
                      <div
                        className={`px-6 ${
                          themeMode === "dark"
                            ? "prospect-info"
                            : "prospect-info "
                        }`}
                      >
                        Campaign History
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        margin: 0, // Remove padding
                      }}
                    >
                      <div className=" -mt-2 px-6">
                        <div className="flex items-center space-x-1 pt-2">
                          <div className="prospect-info-det"> Title: </div>
                          <div className="prospect-info-det">
                            {" "}
                            {openedChat?.prospect?.campaign?.title}
                          </div>
                        </div>
                        <div className="flex items-center space-x-1 pt-2">
                          <div className="prospect-info-det"> Admin: </div>
                          <div className="prospect-info-det">
                            {" "}
                            {openedChat?.prospect?.campaign?.campaign_owner
                              ?.first_name +
                              " " +
                              openedChat?.prospect?.campaign?.campaign_owner
                                ?.last_name}
                          </div>
                        </div>
                        <div className="flex items-center space-x-1 pt-2">
                          <div className="prospect-info-det"> Status: </div>
                          <div className="prospect-info-det">
                            {openedChat?.prospect?.campaign?.status_type ===
                            "activated"
                              ? "Active"
                              : "Inactive"}
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <div
                    className={
                      themeMode === "dark"
                        ? "message-sender-position px-1"
                        : "message-sender-position px-1"
                    }
                    style={{ fontSize: "14px" }}
                  >
                    <div className="pt-1 pb-4">
                      Learn more about{" "}
                      <span
                        onClick={() => {
                          dispatch(
                            getProspectInfo(openedChat?.prospect?.uuid)
                          ).then(() => {
                            handleClickOpenProfileSummary();
                          });
                        }}
                        style={{
                          color: "#BDFF00",
                          textTransform: "none",
                          fontStyle: "italic",
                          cursor: "pointer",
                        }}
                      >
                        <b> {openedChat?.prospect?.name}</b>
                      </span>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
