/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { Typography } from "@mui/material";
import { BeginYourJourney } from "../../homepage/BeginYourJourney";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import "react-multi-carousel/lib/styles.css";
import { useState } from "react";
import { useSelector } from "react-redux";

export const ServiceDetail = () => {
  const selectedService = useSelector((state) => state.home.service);
  return (
    <>
      <Navbar />
      <div
        className="flex flex-col lg:flex md:pt-24 sm:pt-10 xs:pt-8"
        style={{
          backgroundImage: `url(/service.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <div className="text-center text-white p-24 flex flex-col justify-center items-center">
          <Typography
            sx={{
              typography: {
                md: "h3",
                sm: "h4",
                xs: "h4",
              },
            }}
            style={{ fontWeight: 700 }}
          >
            {selectedService.title}
          </Typography>
          <Typography variant="h6" className="py-4 flex md:w-2/3">
            Discover our expertise in developing a wide range of powerful SaaS
            applications tailored to meet your business need{" "}
          </Typography>
        </div>

        <div
          style={{
            // backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Vector3.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
          className="text-white px-16 pt-24 pb-16"
        >
          <Typography variant="h6" className="py-4">
            Comprehensive Financial Analysis:
          </Typography>
          <div className="px-8">
            <ul>
              <li>
                <Typography variant="h7" className="">
                  In-depth review of financial statements to identify trends,
                  risks, and opportunities.{" "}
                </Typography>
              </li>
              <li>
                <Typography variant="h7" className="">
                  Ratio analysis, cash flow forecasting, and performance
                  benchmarking.
                </Typography>
              </li>
            </ul>
          </div>
          <div>
            <Typography variant="h6" className="py-4">
              Budgeting and Forecasting:{" "}
            </Typography>
            <div className="px-8">
              <ul>
                <li>
                  <Typography variant="h7" className="">
                    Creation of detailed budgets aligned with business
                    objectives.
                  </Typography>
                </li>
                <li>
                  <Typography variant="h7" className="">
                    Scenario planning to prepare for market fluctuations.
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <Typography variant="h6" className="py-4">
              Profitability Insights:{" "}
            </Typography>
            <div className="px-8">
              <ul>
                <li>
                  <Typography variant="h7" className="">
                    Identify cost-saving opportunities and optimize revenue
                    streams.
                  </Typography>
                </li>
                <li>
                  <Typography variant="h7" className="">
                    Assess product or service line profitability{" "}
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <Typography variant="h6" className="py-4">
              Risk Management:{" "}
            </Typography>
            <div className="px-8">
              <ul>
                <li>
                  <Typography variant="h7" className="">
                    Evaluate financial risks and propose mitigation strategies.
                  </Typography>
                </li>
                <li>
                  <Typography variant="h7" className="">
                    Ensure compliance with regulatory requirements.{" "}
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <Typography variant="h6" className="py-4">
              Investment Planning:{" "}
            </Typography>
            <div className="px-8">
              <ul>
                <li>
                  <Typography variant="h7" className="">
                    Analyze potential investment opportunities for growth.{" "}
                  </Typography>
                </li>
                <li>
                  <Typography variant="h7" className="">
                    Support decision-making with ROI projections and feasibility
                    studies.{" "}
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <Typography variant="h6" className="py-4">
              Cash Flow Optimization:{" "}
            </Typography>
            <div className="px-8">
              <ul>
                <li>
                  <Typography variant="h7" className="">
                    Monitor inflows and outflows to maintain financial
                    stability.{" "}
                  </Typography>
                </li>
                <li>
                  <Typography variant="h7" className="">
                    Recommend strategies to enhance liquidity and working
                    capital management.
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <Typography variant="h6" className="py-4">
              Tax and Compliance Support:{" "}
            </Typography>
            <div className="px-8">
              <ul>
                <li>
                  <Typography variant="h7" className="">
                    Ensure accurate financial records for tax filing and audits.
                  </Typography>
                </li>
                <li>
                  <Typography variant="h7" className="">
                    Minimize liabilities through proactive tax planning.
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
          <div className="py-4">
            {" "}
            These services empower businesses to make data-driven decisions,
            improve financial health, and achieve long-term growth.
          </div>
        </div>
      </div>
      <BeginYourJourney />
      <Footer />
    </>
  );
};
