import { BorderRight, CheckBox, SmartToyOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Hidden,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  styled,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
} from "@mui/material";
import {
  ArrowDropDownIcon,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment-timezone";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChat, sendScheduledMessage } from "../../../store/messagesSlice";
import { success } from "../../../store/alertSlice";

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    backgroundColor: "red", // Set the desired background color
    color: "white", // Optional: Set text color if needed
  },
});

export const ScheduledMessages = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [signature, setSignature] = useState(false);

  const quickResponses = useSelector((state) => state.settings.quickResponses);

  const openedChat = useSelector((state) => state.messages.openedChat);
  const user = useSelector((state) => state.userProfile.userProfile);

  const [quickResponse, setQuickResponse] = useState("Quick Reply");

  const handleChange = (event) => {
    setQuickResponse(event.target.value);
    setInputValue(event.target.value);
  };
  const handleChangeSignature = (event) => {
    setSignature(event.target.checked);
    handleAddSignature();
  };

  const handleAddSignature = () => {
    const toggleText = `\n\nBest Regards,\n${user.first_name} ${user.last_name} `;
    if (signature) {
      setInputValue((prevValue) =>
        prevValue.endsWith(toggleText)
          ? prevValue.slice(0, -toggleText.length)
          : prevValue
      );
    } else {
      setInputValue((prevValue) => prevValue + toggleText);
    }
    setSignature(!signature);
  };

  return (
    <Box
      className="  flex flex-col justify-between h-screen"
      sx={{ maxHeight: { lg: "450px", md: "450px", sm: "600px", xs: "600px" } }}
    >
      <div className="" style={{ overflowY: "auto" }}>
        {openedChat?.chat_messages
          .filter((n) => n.state === "queued")
          .map((message, index) => (
            <Box sx={{ p: 2 }} className="">
              {index > 0 &&
              moment(message.created_at).format("DD MMM") ===
                moment(
                  openedChat?.chat_messages?.[index - 1]?.created_at
                ).format("DD MMM") ? (
                <div className="-mt-4"></div>
              ) : (
                <Box className="dateBox">
                  <Typography className="date received-text">
                    {moment(message.created_at).format("dddd, DD MMMM YYYY")}
                  </Typography>
                </Box>
              )}
              <Box className="userConversationSeheduledBox">
                <Box
                  className="userConversationBox"
                  style={{
                    borderTop: "0px",
                    BorderRight: "0px",
                    borderLeft: "0px",
                  }}
                >
                  <Box className="userNameTimeBox">
                    <Typography className="received-name">
                      {user.first_name + " " + user.last_name} (You)
                    </Typography>
                    <Typography className="received-text">
                      {moment(message.created_at)
                        .tz(moment.tz.guess())
                        .format("hh:mm z")}{" "}
                    </Typography>
                  </Box>
                  <Typography className="received-text mt-2">
                    {message.message}
                  </Typography>
                </Box>
                <Typography
                  className="reminder-text py-1"
                  sx={{ paddingLeft: "15px" }}
                >
                  Will be delivered at{" "}
                  {moment(message.scheduled_at)
                    .tz(moment.tz.guess())
                    .format("hh:mmA z, DD MMM YYYY")}
                </Typography>
              </Box>
            </Box>
          ))}
      </div>
      {/* Second div aligned at the bottom */}
      <Box className="actionBox mx-2 mt-4  ">
        <TextField
          variant="outlined"
          id="message"
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          multiline
          rows={4}
          placeholder="Type your message..."
          className="custom-textfield"
          InputProps={{
            style: {
              fontSize: "12px",
            },
            disableUnderline: true,
          }}
        />
        <Hidden mdUp>
          <div className="flex flex-col space-y-4 items-center justify-between my-2">
            <div>
              <Box className="flex-left">
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                      className={
                        themeMode === "dark"
                          ? "custom-datetime-picker"
                          : "custom-datetime-picker"
                      }
                    >
                      <DateTimePicker
                        placeholder="Start Time"
                        value={selectedDate}
                        onChange={(newValue) => setSelectedDate(newValue)}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              sx: {
                                "& fieldset": {
                                  border: "none", // Remove the border here
                                },
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "none",
                                  color:
                                    themeMode === "dark" ? "white" : "white",
                                },
                              },
                            }}
                            className={
                              themeMode === "dark"
                                ? "meetings-time-field"
                                : "meetings-time-field"
                            }
                            {...params}
                          />
                        )}
                        PopperProps={{
                          component: StyledPopper, // Apply custom styles for the popper
                        }}
                      />
                    </Box>
                  </LocalizationProvider>
                </>
                <>
                  <Checkbox
                    // className="remind-check-box"
                    checked={signature}
                    onChange={handleChangeSignature}
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#FFF",
                      },
                    }}
                  />
                  <Typography variant="body2" className="reminder-text">
                    Add Signature
                  </Typography>
                </>
              </Box>
            </div>
            <div>
              <div style={{ border: "1px solid white", borderRadius: "30px" }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    className="msg-quick"
                    disableUnderline
                    value={quickResponse}
                    onChange={handleChange}
                    sx={{
                      maxHeight: "16px",
                      margin: 0, // Remove margin
                      padding: 0,
                      "& .MuiSelect-icon": {
                        color: "white", // Customize the icon color
                      },
                    }}
                  >
                    <MenuItem
                      className=""
                      defaultValue
                      value={"Quick Reply"}
                      key={"empty"}
                      disabled
                    >
                      Quick Reply
                    </MenuItem>
                    {quickResponses?.data?.map((quickResponse) => (
                      <MenuItem
                        className=""
                        value={quickResponse.message}
                        key={quickResponse.uuid}
                      >
                        {quickResponse.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
              </div>
            </div>
            <div>
              <>
                <Tooltip
                  title={
                    openedChat?.prospect?.automation_enabled === true
                      ? "can't send message due to automation"
                      : "send message"
                  }
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#FF000099" }}
                      className="smart-reply-btn py-2 m-0"
                      disabled={
                        inputValue === "" ||
                        openedChat?.prospect?.automation_enabled === true
                      }
                      onClick={(e) => {
                        dispatch(
                          sendScheduledMessage({
                            id: openedChat.uuid,
                            message: inputValue,
                            owner_type: "app_user",
                            scheduled_at: moment(selectedDate?.$d).format(
                              "YYYY-MM-DDTHH:mm:ss.SSSZ"
                            ),
                          })
                        ).then((resp) => {
                          if (resp?.payload?.status === 200) {
                            setInputValue("");
                            dispatch(getChat(openedChat.uuid));
                            dispatch(success("Message sent"));
                          }
                        });
                      }}
                    >
                      <span style={{ fontSize: "13px", color: "white" }}>
                        Schedule Message
                      </span>
                    </Button>
                  </span>
                </Tooltip>
              </>
            </div>
          </div>
        </Hidden>
        <Hidden mdDown>
          <Box className="flex-container">
            <Box className="flex-left">
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    className={
                      themeMode === "dark"
                        ? "custom-datetime-picker"
                        : "custom-datetime-picker"
                    }
                  >
                    <DateTimePicker
                      placeholder="Start Time"
                      value={selectedDate}
                      onChange={(newValue) => setSelectedDate(newValue)}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              "& fieldset": {
                                border: "none", // Remove the border here
                              },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                                color: themeMode === "dark" ? "white" : "white",
                              },
                            },
                          }}
                          className={
                            themeMode === "dark"
                              ? "meetings-time-field"
                              : "meetings-time-field"
                          }
                          {...params}
                        />
                      )}
                      PopperProps={{
                        component: StyledPopper, // Apply custom styles for the popper
                      }}
                    />
                  </Box>
                </LocalizationProvider>
              </>
              <>
                <Checkbox
                  // className="remind-check-box"
                  checked={signature}
                  onChange={handleChangeSignature}
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#FFF",
                    },
                  }}
                />
                <Typography variant="body2" className="reminder-text">
                  Add Signature
                </Typography>
              </>
            </Box>
            <Box className="flex-right">
              <></>

              <div style={{ border: "1px solid white", borderRadius: "30px" }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    className="msg-quick"
                    disableUnderline
                    value={quickResponse}
                    onChange={handleChange}
                    sx={{
                      maxHeight: "16px",
                      margin: 0, // Remove margin
                      padding: 0,
                      "& .MuiSelect-icon": {
                        color: "white", // Customize the icon color
                      },
                    }}
                  >
                    <MenuItem
                      className=""
                      defaultValue
                      value={"Quick Reply"}
                      key={"empty"}
                      disabled
                    >
                      Quick Reply
                    </MenuItem>
                    {quickResponses?.data?.map((quickResponse) => (
                      <MenuItem
                        className=""
                        value={quickResponse.message}
                        key={quickResponse.uuid}
                      >
                        {quickResponse.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
              </div>
              <>
                <Tooltip
                  title={
                    openedChat?.prospect?.automation_enabled === true
                      ? "can't send message due to automation"
                      : "send message"
                  }
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#FF000099" }}
                      className="smart-reply-btn py-2 m-0"
                      disabled={
                        inputValue === "" ||
                        openedChat?.prospect?.automation_enabled === true
                      }
                      onClick={(e) => {
                        dispatch(
                          sendScheduledMessage({
                            id: openedChat.uuid,
                            message: inputValue,
                            owner_type: "app_user",
                            scheduled_at: moment(selectedDate?.$d).format(
                              "YYYY-MM-DDTHH:mm:ss.SSSZ"
                            ),
                          })
                        ).then((resp) => {
                          if (resp?.payload?.status === 200) {
                            setInputValue("");
                            dispatch(getChat(openedChat.uuid));
                            dispatch(success("Message sent"));
                          }
                        });
                      }}
                    >
                      <span style={{ fontSize: "13px", color: "white" }}>
                        Schedule Message
                      </span>
                    </Button>
                  </span>
                </Tooltip>
              </>
            </Box>
          </Box>
        </Hidden>
      </Box>
    </Box>
  );
};
