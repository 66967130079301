import * as React from "react";
import Dialog from "@mui/material/Dialog";
import {
    Grid,
    Typography,
    Backdrop,
    Button
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../../utils/globalFunctions.js";

const logoYellow =
    "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoFace.png";
const code = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/DBcardCode.png"

const cardBrandLogos = {
    Visa: "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/visa.png",
    MasterCard:
      "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/mastercard.png",
    Amex: "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/amex.png",
    default:
      "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/defCard.png",
};

export default function ViewDebitCard({ open, handleClose, stripeMethod }) {
    const dispatch = useDispatch();
    const themeMode = useSelector((state) => state.themeMode.mode);
    const user = useSelector((state) => state.userProfile.userProfile);

    return (
        <Backdrop>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "FFFFFF",
                    backdropFilter: "blur(4px)",
                }}
                PaperProps={{
                    sx: {
                        background: "linear-gradient(180deg, #D20000 0%, #6C0000 100%)", 
                        color:"#FFF",
                        borderRadius: "15px", 
                        padding: {md:"20px",sm:"20px",xs:"0px"}, 
                        width: {
                            md: "100%", 
                            sm: "100%", 
                            xs:"100%"
                        },
                        height: {
                            md: "auto", 
                            sm: "auto", 
                            xs: "30vh", 
                        },
                        maxWidth: {
                            md: "444px",
                            sm: "444px",
                            xs: "260px",
                        },
                        minWidth:{
                            md: "444px",
                            sm: "444px",
                            xs: "260px",
                        },
                        overflowX:"hidden",
                        overflowY: "hidden",
                        position:"relative"
                        
                    },
                }}
                fullWidth
            >
                <Grid sx={{ width: {md:"100%",sm:"100%",xs:"fit-content"}, transform: { md: "scale(1)", sm: "scale(1)", xs: "scale(0.6)" }, top: { md: "0px", sm: "0px", xs: "-30px" }, left: { md: "0px", sm: "0px", xs:"-45px"},position:{mdd:"",sm:"",xs:"relative"} }}>
                
                    <Grid sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <Typography sx={{ fontSize: "23.4px", fontWeight: "700" }}>
                            {/* Debit Card */}
                            {stripeMethod?.stripe_fields ? stripeMethod?.stripe_fields?.brand : stripeMethod?.stripe_payment_method?.card?.brand ? capitalizeFirstLetter(stripeMethod?.stripe_payment_method?.card?.brand) : "Card"}
                        </Typography>
                        <img src={logoYellow} alt="" />
                    </Grid>
                    <Grid>
                        <img src={code} />
                    </Grid>
                    <Grid sx={{width:"fit-content"}}>
                        <Typography sx={{ fontSize: "36px", fontWeight: "700", textAlign: "center", textWrap: "nowrap" }}>
                            ****    ****   ****  {stripeMethod?.stripe_fields ? stripeMethod?.stripe_fields?.last4 : stripeMethod?.stripe_payment_method?.card?.last4}
                        </Typography>
                    </Grid>
                    <Grid my={2} sx={{ display: "flex", justifyContent: "space-between",width:"100%" }}>
                        <Typography sx={{ fontSize: "10.4px", fontWeight: "700", display: "flex", alignItems: "center" }}>
                            CVC <span style={{ fontSize: "21px", display: "flex", alignItems: "center", marginLeft: "10px" }}> *** </span>
                        </Typography>
                        <Typography sx={{ fontSize: "10.4px", fontWeight: "700", display: "flex", alignItems: "center" }}>
                            Valid Thru <span style={{ fontSize: "21px", display: "flex", alignItems: "center", marginLeft: "10px" }}>{stripeMethod?.stripe_fields ? stripeMethod?.stripe_fields?.exp_month : stripeMethod?.stripe_payment_method?.card?.exp_month}/{String(stripeMethod?.stripe_fields ? stripeMethod?.stripe_fields?.exp_year : stripeMethod?.stripe_payment_method?.card?.exp_year)?.slice(-2)}</span>
                        </Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", justifyContent: "space-between" ,width:"100%"}}>
                        <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                            {/* {user?.first_name} {user?.last_name} */}
                        </Typography>
                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={
                                    cardBrandLogos[stripeMethod?.stripe_fields ? stripeMethod?.stripe_fields?.brand : capitalizeFirstLetter(stripeMethod?.stripe_payment_method?.card?.brand)] || cardBrandLogos["default"]
                                }
                                alt={stripeMethod?.stripe_fields ? stripeMethod?.stripe_fields?.brand : stripeMethod?.stripe_payment_method?.card?.brand || "default"}
                                style={{ width: "30px", height: "30px", objectFit: "contain" }}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Dialog>
        </Backdrop>


    );
}
