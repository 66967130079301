import { Grid, Typography } from "@mui/material";
import { OurTeamCard } from "./OurTeamCard";
import team from "./team";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const OurTeam = () => {
  return (
    <div className="text-white">
      <Typography
        sx={{
          typography: {
            md: "h3",
            sm: "h4",
            xs: "h4",
          },
        }}
        style={{ fontWeight: 700 }}
        className="pt-10 text-center"
      >
        Meet our team{" "}
      </Typography>
      <div className="flex justify-center pt-4">
        <div className="our-team-content flex md:w-2/3 px-8">
          Discover the faces that drive our innovation and growth.
          <br />
          Together, we're committed to delivering exceptional results for you.
        </div>
      </div>

      <div className="my-24 md:px-8 px-8">
        <Carousel responsive={responsive}>
          {team.map((member, index) => (
            <OurTeamCard
              key={index}
              name={member.name}
              designation={member.designation}
              img={member.img}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};
