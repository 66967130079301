import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";

import { useNavigate } from "react-router-dom";

const defaultValues = {
  title: "",
};

const schema = yup
  .object()
  .shape({
    title: yup.string().required("You must enter sign"),
  })
  .required();

export default function CustomSignDialog({ open, handleClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function onSubmit(data) {
    console.log("🚀 ~ onSubmit ~ data:", data);

    handleClose();
    reset(defaultValues);
  }
  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "FFFFFF",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "FFFFFF", // Inner background color
            borderRadius: "10px",
            border: "1px solid white",
            color: themeMode === "dark" ? "white" : "black",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-8"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "black"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "white" }}
          >
            <div
              className={
                themeMode === "dark"
                  ? "create-campaign-dialog-text pb-2"
                  : "create-campaign-dialog-text-light pb-2"
              }
            >
              Set your custom signature
            </div>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  required
                  className={
                    themeMode === "dark"
                      ? "create-campaign-dialog-field w-full"
                      : "create-campaign-dialog-field-light w-full"
                  }
                  size="small"
                  sx={{
                    "& .MuiInputBase-input": {
                      color: themeMode === "dark" ? "white" : "black",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        color: themeMode === "dark" ? "white" : "black",
                      },
                    },
                  }}
                />
              )}
              name="title"
              control={control}
            />

            <div className="flex w-full justify-center pt-8">
              <Button
                className={
                  themeMode === "dark"
                    ? "create-button p-2"
                    : "create-button-light p-2"
                }
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                <span className="create-button-text">Set Signature</span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
